section {
    padding: 40px 0;
}

#invoice {
    box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #E6E6F2;
    border-radius: .25rem;
}

#invoice {
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    -moz-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
}

#invoice .card-header {
    background-color: #fff;
    border-bottom: 1px solid #E6E6F2
}

#invoice .card-header .inv-brand {
    font-size: 22px;
    text-decoration: none;
}

#invoice .card-header h1 {
    margin-bottom: 0;
}

#invoice h3 {
    font-size: 20px
}

#invoice h5 {
    font-size: 15px;
    line-height: 26px;
    color: #3D405C;
    margin: 0px 0px 15px 0px;
    font-family: 'Circular Std Medium'
}

#invoice .info-en td,
#invoice .info-en th {
    padding: .25rem;
    vertical-align: top;
    border-top: none;
    font-size: 12px;
}

#invoice #info {
    height: auto;
}

#invoice .info-en thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #DEE2E6;
    border-top: 2px solid #DEE2E6;
    background-color: rgba(0, 0, 0, .05);
}

.inv-info td {
    text-align: right;
}

.items .center {
    text-align: center;
}

.items .log-box {
    width: 100px;
}

.total .right {
    text-align: right;
}

.sub-total tbody+tbody {
    border-top: 2px solid #000000;
}

#invoice .qr-code p {
    font-size: 12px;
}

#invoice .card-footer p {
    font-size: 12px;
    text-align: center;
}

.text-dark {
    color: #3D405C !important
}

#invoice .card-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid rgba(0, 0, 0, .125);
}

#invoice #ship {
    display: table-row;
}

.button-deliver-custom {
    border: none;
    padding: 10px;
    border-radius: 5px;
    background-color: #777f80;
    color: #ffff;
}

.button-deliver-custom-inactive {
    border: none;
    padding: 10px;
    border-radius: 5px;
    background-color: #e9e9e9;
    color: #ffff;
    cursor: auto !important;
}