.description {
  color: black;
}
.table {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text-capitalize {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.container-content {
  border-radius: 10px;
  overflow: hidden;
  width: 400px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid rgba(0, 0, 0, 0.347); */
  /* padding: 1rem; */
  /* gap: 0 1rem; */
  /* background-color: antiquewhite */
}
.image {
  width: 9%;
  height: 80%;
  margin-left: 5px;
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.description {
  width: 65%;
  padding: 1rem;
}
.container-content svg {
  padding: 1rem;
  /* height: 40px !important; */
  font-size: 1.5rem;
}
.btn-primary {
  margin-top: 2rem;
  padding: 10px 20px;
  cursor: pointer;
}
.serialize-side-drawer {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding-bottom: 30% !important;
}

.serialize-side-drawer::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
  background: transparent !important;
}
.serialize-side-drawer:-webkit-scrollbar-track {
  border-radius: 10px !important;
  background-color: transparent !important;
}
.serialize-side-drawer::-webkit-scrollbar {
  width: 12px !important;
  background-color: transparent !important;
}

.drawer {
  /* top: 0; */
  top: 60px !important;
  padding-bottom: 0 !important;
  padding-top: 78px;
  width: 500px !important;
  height: 92vh !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  justify-content: left !important;
}
.container-content {
  height: 70px !important;
}
.container-content .image .sequentialize-img {
  width: 20% !important;
  height: auto;
}
.sequ-btn-tooltip {
  text-align: end !important;
}
.vertical-sequentialize-btn {
  color: #808080 !important;
  float: right;
  background: white !important;
  margin-right: 20px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid white !important;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.vertical-sequentialize-btn:hover {
  color: #808080 !important;
  float: right;
  background: white;
  margin-right: 20px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid white !important;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.sequence-drwa-icons {
  width: 50px;
}
.dragdrop-side {
  position: fixed;
  top: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 12px;
}
.container-content-design {
  border-radius: 10px;
  overflow: hidden;
  width: 400px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 1px;
  border: 1px solid rgb(238, 238, 238);
  width: 100%;
}
.container-content-design svg {
  font-size: 2.5rem;
  padding-right: 1rem;
}
